import { useEffect, useState } from "react";

export default function useScript(src?: string, removeOnUnmount?: boolean) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (src) {
      try {
        let script = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement;
        if (!script) {
          script = document.createElement("script");
          script.src = src;
          script.async = true;
          script.onload = function () {
            setLoaded(true);
          };
          document.body.appendChild(script);
        } else {
          setLoaded(true);
        }
      } catch (err) {
        console.error(`An error occurred while loading ${src}`);
      }
    }
    return () => {
      if (removeOnUnmount && src) {
        let script = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement;
        if (script) {
          script.parentNode?.removeChild(script);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded;
}
