import { GetStaticPropsContext, InferGetStaticPropsType } from "next";
import { join } from "path";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import { sequentialLoadingState } from "~/atoms/sequentialLoading";
import AccessibilityStatement from "~/components/editorials/accessibility-statement/accessibility-statement";
import EditorialEntry from "~/components/editorials/editorial-entry";
import LayoutPage from "~/components/layout/layout-page";
import { usePageTracking } from "~/contexts/pagetrackings";
import appRoutes from "~/utils/app-routes";
import Constants from "~/utils/constants";
import { isActiveEntry } from "~/utils/editorial-utils";
import ssrUtils from "~/utils/ssr-utils";
import TrackingUtils from "~/utils/tracking-utils";

type Props = InferGetStaticPropsType<typeof getStaticProps>;

export default function EntryPreviewPage(props: Props) {
  usePageTracking("editorial", TrackingUtils.mapTrackEditorialPage(props));
  const sequentialLoadingReady = useRecoilValue(sequentialLoadingState);

  useEffect(() => {
    const hideDidomiNotice = (unmount?: boolean) => {
      if (
        props.slugs?.["default"] === appRoutes.COOKIE_POLICY ||
        props.slugs?.["default"].includes(appRoutes.PRIVACY_POLICY)
      ) {
        if (sequentialLoadingReady.didomi) {
          const didomiHost = document.getElementById("didomi-host");
          if (didomiHost) {
            if (unmount) {
              didomiHost.setAttribute("data-visible", "true");
            } else {
              didomiHost.setAttribute("data-visible", "false");
            }
          }
        }
      }
    };

    hideDidomiNotice();

    return () => {
      hideDidomiNotice(true);
    };
  }, [props.slugs, sequentialLoadingReady.didomi]);

  return (
    <LayoutPage {...props}>
      <>
        {props.slugs?.["default"] === appRoutes.ACCESSIBILITY_STATEMENT ? <AccessibilityStatement /> : null}
        {props.page.components?.filter(isActiveEntry).map((entry) => (
          <EditorialEntry key={entry.sys.id} entry={entry} />
        ))}
      </>
    </LayoutPage>
  );
}

export async function getStaticPaths() {
  return {
    paths: [], // TODO: add frequent pages
    fallback: "blocking",
  };
}

export async function getStaticProps(context: GetStaticPropsContext) {
  if (context.locale === "default") {
    return {
      notFound: true,
    };
  }

  //SEO issues: if the slug returned by the editorial pages is "homepage", redirect to the main page
  if (context.params?.editorial?.[0] === process.env.NEXT_PUBLIC_HOMEPAGE_SLUG!) {
    return {
      redirect: {
        destination: join("/", context.locale!),
        permanent: false,
      },
    };
  }

  const pageData = await ssrUtils.getServerSideEditorialPageData(context, context.draftMode, context.params?.editorial);

  if (!pageData.page) {
    const previewData = await ssrUtils.getServerSideEditorialPreviewPageData(context, context.params?.editorial);

    if (previewData.page?.fallbackUrl) {
      if (previewData.page.fallbackUrl === "NOT_FOUND") {
        return {
          notFound: true,
        };
      }

      let destination = join("/", context.locale!, previewData.page.fallbackUrl, "/");

      return {
        redirect: {
          destination,
          permanent: false,
        },
      };
    } else {
      // The page could not be found on draft mode or it did not have a fallback url.

      return {
        notFound: true,
      };
    }
  }

  if (pageData.page.fallbackUrl) {
    console.warn(`Detected fallback url for editorial page ${context.params?.editorial}: ${pageData.page.fallbackUrl}`);

    if (pageData.page.fallbackUrl === "NOT_FOUND") {
      return {
        notFound: true,
      };
    }

    let destination = join("/", context.locale!, pageData.page.fallbackUrl, "/");

    return {
      redirect: {
        destination,
        permanent: false,
      },
    };
  }

  return {
    props: {
      ...pageData,
    },
    revalidate: Constants.DEFAULT_STATIC_PAGE_TIME,
  };
}
