import { useRouter } from "next/router";

import Container from "~/components/common/container";
import useScript from "~/hooks/use-script";
import { ApiUtils } from "~/shared/api/api-utils.class";
import { UsablenetConfiguration } from "~/types/usablenet";
import CommonUtils from "~/utils/common-utils";
import crossCountryUtils from "~/utils/crossCountry-utils";

import styles from "./accessibility-statement.module.scss";

export default function AccessibilityStatement() {
  const usablenetConfig = CommonUtils.parseJsonSafely(
    process.env.NEXT_PUBLIC_USABLENET_CONFIG!
  ) as UsablenetConfiguration;
  const router = useRouter();
  const language = crossCountryUtils.getCurrentLanguageCode(router);

  useScript(
    usablenetConfig?.enabled
      ? ApiUtils.interpolateParams(usablenetConfig.scripts.a11yStatement, { lang: language })
      : "",
    true
  );

  return usablenetConfig?.enabled ? (
    <Container className={styles.container}>
      <div id="a40AccessibilityStatement" />
    </Container>
  ) : null;
}
